import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import qs from 'query-string';
import * as AppActions from '../../AppActions';
import Cart from '../../Components/Cart';
import * as OrderUtil from '../../Utils/OrderUtil';
import Theme from '../../Theme';
import config from '../../../data.json';
import {ErrorOutline} from '@styled-icons/material/ErrorOutline';
import {Pending} from '@styled-icons/material-outlined/Pending';
import {CheckCircle} from '@styled-icons/material-outlined/CheckCircle';
import {INVOICE_TYPE, DONATION_ORG} from '../../dictionary';
import constants from '../../constants';

export default function OrderDetail(props) {
  const params = qs.parse(props.location?.search);
  const [order, setOrder] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const couponDiscountItem = order
    ? order.discount_items.find((x) => !!x.promotion?.coupon)
    : undefined;

  const getOrder = React.useCallback(async (order_number) => {
    const _order = await AppActions.fetchOrderDetail({
      order_number,
    });

    setOrder(_order);
    return _order;
  }, []);

  React.useEffect(() => {
    // online payment polling refresh until payment_status !== pending
    let timer;
    function setTimer() {
      if (timer) {
        console.log('clear timer');
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        let _order = await getOrder(order.order_number);
        if (!_order?.payment_resolved) {
          setTimer();
        } else {
          setOrder(_order);
          console.log('clear timer');
          clearTimeout(timer);
          AppActions.setLoading(false);
        }
      }, 3000);
    }
    if (order && !order.payment_resolved && order.payment_type !== 'cash') {
      AppActions.setLoading(true);
      console.log('clear timer');
      console.log('pending order', order);
      setTimer();
    }
    return () => {
      console.log('clear timer');
      clearTimeout(timer);
    };
  }, [order, getOrder]);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      setLoading(true);
      setError(null);
      try {
        setOrder(await getOrder(params.order_number));
      } catch (ex) {
        console.warn('OrderDetail.useEffect', ex);
        setError(ex || {});
      }
      setLoading(false);
      AppActions.setLoading(false);
    }

    if (params?.order_number) {
      fetchData();
    }
  }, [params?.order_number, getOrder]);

  if (loading || !order || !order?.payment_resolved) {
    return (
      <Wrapper>
        <div className="content">
          <h2>訂單處理中...</h2>
        </div>
      </Wrapper>
    );
  } else if (error) {
    return (
      <Wrapper>
        <div className="content" style={{minHeight: 'calc(100vh - 100px)'}}>
          <div className="error-panel">
            {error?.status === 403 ? (
              <>
                <div style={{fontSize: 22, marginBottom: 10}}>
                  本頁面需使用Laya App開啟
                </div>
                <Button
                  size="large"
                  type="primary"
                  href={`${config.mobileAppHost}://OrderDetail?order_number=${params?.order_number}`}>
                  立刻前往
                </Button>
              </>
            ) : (
              <div style={{fontSize: 22}}>查無此訂單</div>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }

  console.log('order config', order.config);
  const helper = new OrderUtil.OrderDisplayHelper(order);

  return (
    <Wrapper>
      <div className="content">
        {order.payment_status === 'failed' && (
          <div className="payment-status">
            <ErrorOutline size={80} color="red" />
            <div className="text">付款失敗</div>
          </div>
        )}

        {order.payment_status === 'cancelled' && (
          <div className="payment-status">
            <ErrorOutline size={80} color="red" />
            <div className="text">已取消</div>
          </div>
        )}

        {order.payment_status === 'success' && (
          <div className="payment-status">
            <CheckCircle size={80} color={Theme.colors.main} />
            <div className="text" style={{color: Theme.colors.main}}>
              付款完成
            </div>
          </div>
        )}

        {order.payment_status === 'pending' && (
          <div className="payment-status">
            <Pending size={80} color={Theme.colors.main} />
            <div
              className="text"
              style={{color: Theme.colors.main, marginBottom: 5}}>
              尚未完成付款
            </div>
            <div className="text" style={{color: Theme.colors.main}}>
              請至櫃檯完成付款
            </div>
          </div>
        )}

        {order.payment_status === 'failed' ? null : (
          <React.Fragment>
            <div style={{fontSize: 22, marginBottom: 10}}>取餐資訊</div>
            <div style={styles.panel}>
              <div style={{padding: 16}}>
                <div style={{...styles.field, paddingBottom: 0}}>
                  <div style={styles.label}>取餐時間</div>
                  <div>
                    <div style={styles.text}>{helper.pickupDate()}</div>
                    <div style={{...styles.text, ...{color: Theme.colors.red}}}>
                      {helper.pickupTime()}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{height: 1, backgroundColor: '#ccc'}} />

              <div style={{padding: 16}}>
                <div style={styles.field}>
                  <div style={styles.label}>訂餐類型</div>
                  <div>
                    <div style={styles.text}>{helper.orderType()}</div>
                    {!order.config.time && (
                      <div style={{color: '#aaa'}}>
                        餐點現做須等待
                        {order.store?.preparation_config
                          ? order.store?.preparation_config?.preparation_time *
                            (order.total >
                            order.store?.preparation_config?.doubling_threshold
                              ? 2
                              : 1)
                          : constants.DEFAULT_STORE_PREPARATION}
                        分鐘
                      </div>
                    )}
                  </div>
                </div>

                {!helper.isTableOrder() && (
                  <div style={styles.field}>
                    <div style={styles.label}>取餐號碼</div>
                    <div>
                      <div style={styles.text}>{helper.pickupNumber()}</div>
                      <div style={{color: '#aaa'}}>手機末三碼</div>
                    </div>
                  </div>
                )}

                <div style={{...styles.field, paddingBottom: 0}}>
                  <div style={styles.label}>門市資訊</div>
                  <div>
                    <div style={styles.text}>{order.store?.name}</div>
                    <div style={{color: '#aaa'}}>
                      訂單成立後如需修改聯繫門市
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        {!helper.isTableOrder() ? (
          <React.Fragment>
            <div style={{fontSize: 22, marginBottom: 10}}>取餐人資訊</div>
            <div style={{...styles.panel, padding: 16}}>
              <div style={styles.field}>
                <div style={styles.label}>名稱</div>
                <div style={styles.text}>{order.config.buyer_name}</div>
              </div>

              <div style={styles.field}>
                <div style={styles.label}>電話</div>
                <div style={styles.text}>{order.config.buyer_phone}</div>
              </div>
            </div>
          </React.Fragment>
        ) : null}

        {order.payment_status === 'failed' ? null : (
          <React.Fragment>
            <div style={{fontSize: 22, marginBottom: 10}}>訂單資訊</div>
            <div style={{...styles.panel, padding: 16}}>
              <div style={styles.field}>
                <div style={styles.label}>訂餐編號</div>
                <div>
                  <div style={styles.text}>{helper.orderId()}</div>
                </div>
              </div>

              <div style={styles.field}>
                <div style={styles.label}>送單時間</div>
                <div>
                  <div style={styles.text}>{helper.createdDateTime()}</div>
                </div>
              </div>

              {helper.isTableOrder() && (
                <div style={styles.field}>
                  <div style={styles.label}>桌號</div>
                  <div>
                    <div style={styles.text}>{order.config.table}</div>
                  </div>
                </div>
              )}

              <div style={styles.field}>
                <div style={styles.label}>訂單金額</div>
                <div>
                  <div style={styles.text}>${order.total}</div>
                </div>
              </div>

              <div style={styles.field}>
                <div style={styles.label}>付款方式</div>
                <div>
                  <div style={styles.text}>{helper.paymentType()}</div>
                </div>
              </div>

              {helper.invoiceType().enable && (
                <div style={styles.field}>
                  <div style={styles.label}>發票資訊</div>
                  <div>
                    <div style={styles.text}>
                      {INVOICE_TYPE[helper.invoiceType().invoiceType]}
                    </div>
                  </div>
                  <div style={{...styles.text, marginLeft: 20}}>
                    {helper.invoiceType().invoiceType === 'donation_code'
                      ? DONATION_ORG[helper.invoiceType().carrier]
                      : helper.invoiceType().carrier}
                  </div>
                </div>
              )}

              <div style={styles.field}>
                <div style={styles.label}>訂單備註</div>
                <div>
                  <div style={styles.text}>{order.config.note || '---'}</div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        {couponDiscountItem ? (
          <React.Fragment>
            <div style={{fontSize: 22, marginBottom: 10}}>優惠券</div>
            <div style={styles.panel}>
              <div style={styles.field}>
                <div style={styles.label}>使用優惠券</div>
                <div>
                  <div style={styles.text}>
                    {couponDiscountItem.promotion?.name}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}

        <div style={{fontSize: 22, marginBottom: 10}}>餐點資訊</div>
        <div style={styles.panel}>
          <Cart order={order} />
        </div>
      </div>
    </Wrapper>
  );
}

const styles = {
  panel: {
    backgroundColor: Theme.colors.bgYellow,
    borderRadius: 12,
    boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
    marginBottom: 30,
  },
  field: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: 10,
  },
  label: {
    fontSize: 18,
    color: 'rgb(88, 78, 54)',
    width: 88,
  },
  text: {
    fontSize: 18,
    lineHeight: 1.33,
  },
};

const Wrapper = styled.div`
  padding: 80px 20px 20px 20px;
  min-height: 500px;
  background-color: #fff;

  & > .content {
    border-radius: 8px;
    padding: 10px;
    margin: 0 auto;
    max-width: 720px;

    & > .payment-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      & > .text {
        color: red;
        font-size: 24px;
      }
    }

    & > .error-panel {
      padding: 20px;
      border-radius: 16px;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.36);
    }
  }
`;
