import React from 'react';
import * as TextUtil from './TextUtil';
import {objectIdToDateTime} from './DateUtil';
import Theme from '../Theme';

export class OrderDisplayHelper {
  constructor(order) {
    this.order = order;
    this.config = order.config;

    if (order.checkout) {
      const checkoutTime = objectIdToDateTime(order.checkout);
      this.created = checkoutTime.getTime();
    } else {
      this.created = order.created;
    }
  }

  pickupDate() {
    if (this.order.pick_time) {
      return this.order.pick_time.split(' ')[0];
    }

    if (!this.config.time) {
      return TextUtil.getDateStrFromTime(this.created);
    } else {
      return this.config.time.split(' ')[0];
    }
  }

  pickupTime() {
    if (this.order.pick_time) {
      return this.order.pick_time.split(' ')[1].slice(0, -3);
    }

    if (!!this.config.table) {
      return `${this.config.table}號桌`;
    } else if (!this.config.time) {
      return '立即取餐';
    }

    return this.config.time.split(' ')[1].slice(0, -3);
  }

  orderType() {
    if (!this.config.time) {
      return '立即取餐';
    }

    return '預約取餐';
  }

  pickupNumber() {
    return this.config.buyer_phone.slice(-3);
  }

  orderId() {
    return this.order.order_id;
  }

  createdDateTime() {
    return `${TextUtil.getDateStrFromTime(
      this.created,
    )} ${TextUtil.getTimeStrFromTime(this.created)}`;
  }

  paymentType() {
    if (this.order.payment_type === 'cash') {
      return '現金付款';
    } else {
      return '線上付款';
    }
  }

  isTableOrder() {
    return !!this.order.config.table;
  }

  renderOrderTypeLabel() {
    if (!!this.order.config.table) {
      return (
        <div style={{...Theme.tag}}>
          <div style={{fontSize: 14}}>桌邊</div>
        </div>
      );
    } else if (!this.order.config.time) {
      return (
        <div style={{...Theme.tag}}>
          <div style={{fontSize: 14}}>立即取餐</div>
        </div>
      );
    } else {
      return (
        <div style={{...Theme.tag}}>
          <div style={{fontSize: 14}}>預約</div>
        </div>
      );
    }
  }

  invoiceType() {
    return {
      enable: this.order.store?.ENABLE_INVOICE,
      invoiceType: this.order.config.mobile_carrier
        ? 'mobile_carrier'
        : this.order.config.uni_no
        ? 'uni_no'
        : this.order.config.donation_code
        ? 'donation_code'
        : 'manual_invoice',
      carrier:
        this.order.config.mobile_carrier ||
        this.order.config.uni_no ||
        this.order.config.donation_code,
    };
  }
}
