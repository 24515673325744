export function objectIdToDateTime(objectId) {
  try {
    if (typeof objectId === 'string') {
      return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
    }
  } catch (error) {
    console.warn(error);
  }
  return new Date(0);
}
