function mapLineBreak(text) {
  return (text || '').replace(/\n/g, '<br/>');
}

function formatDate(timestamp) {
  try {
    return new Date(timestamp).toISOString().split('T')[0];
  } catch (ex) {
    return null;
  }
}

function checkNamePhoneEmail({name, phone, email}) {
  if (!name || !phone || !email) {
    return false;
  }

  const phonePattern = /^09[0-9]{8}$/;
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  return (
    name.length >= 2 && phonePattern.test(phone) && emailPattern.test(email)
  );
}

function checkPhone(phone) {
  const phonePattern = /^09[0-9]{8}$/;
  return phone && phonePattern.test(phone);
}

function checkName(name) {
  return name && name.trim().length >= 1;
}

function checkEmail(email) {
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return email && emailPattern.test(email);
}

function zeroPad(str) {
  return ('00' + str).slice(-2);
}

function getDateStrFromTime(time) {
  const dt = new Date(time);
  return `${dt.getFullYear()}-${zeroPad(dt.getMonth() + 1)}-${zeroPad(
    dt.getDate(),
  )}`;
}

function getTimeStrFromTime(time) {
  const dt = new Date(time);
  return `${zeroPad(dt.getHours())}:${zeroPad(dt.getMinutes())}`;
}

module.exports = {
  mapLineBreak,
  formatDate,
  checkPhone,
  checkName,
  checkEmail,
  checkNamePhoneEmail,
  getDateStrFromTime,
  getTimeStrFromTime,
};
